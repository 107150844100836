import React,{ useState, useRef, useMemo } from 'react'
import axios from "axios";
import { Pagination } from '@mui/material';
import { useQuery } from "@tanstack/react-query";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {AiOutlineCloudDownload} from 'react-icons/ai'
import Bread from '../../common/Bread'
import {useParams} from 'react-router-dom'
import { LangContext } from '../../context/LangContext';
import './investorRelation.scss'
import Loader from '../../common/Loader';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#ADD8E6',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontFamily: 'DM Sans, sans-serif',
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(173, 216, 230, 0.4)',
     
    },
    // hide last border
    '&:last-child td, &:last-child th': {
    //   border: 0,
    },
  }));


const InvestorRelation = () => {
    const [isPage, setIsPage] = useState(1);
    const [title, setTitle] = useState("")
    const items = useRef([]);
  
    const lang = React.useContext(LangContext);
    const nep = lang.state.np;

      const {slug} = useParams()


      function titleSwitch () {
        switch (slug) {
          case "agm-minute": {
            setTitle("AGM Minute")
          }
          break;
          case "annual-report": {
            setTitle("Annual Report")
          }
          break;
          case "financial-report": {
            setTitle("Financial Highlights")
          }
          break;
          case "key-indicators": {
            setTitle("Key Indicators")
          }
          break;
          case "corporate-governance": {
            setTitle("Corporate Governance Report")
          }
          break;
          case "sebon-yearly-report": {
            setTitle("Sebon Yearly Report")
          }
          break;
          case "sebon-quarterly-report": {
            setTitle("Sebon Quarterly Report")
          }
          break;
          case "letter-to-sebon": {
            setTitle("Letter To Sebon")
          }
          break;
          case "basel-report": {
            setTitle("Basel Disclosure")
          }
          break;
          default:{
            setTitle("Investor Relation")
          }
          break;
        }
      }
    
      var breadMemo = useMemo(titleSwitch, [slug])

      const { isLoading, error, data, isFetching } = useQuery({
        queryKey: ["investorData", isPage, slug],
        queryFn: () =>
          axios
            .get(`${process.env.REACT_APP_BASE_URL}/investor_relation/all/${slug}/${isPage}`)
            .then((res) => res.data),
      });
      
    
      if (isFetching) return(
        <Loader />
      );
    
      if (isLoading) return(
        <Loader />
      );
    
      if (error) return "An error has occurred: " + error.message;

      let breadcrumList;

      if (nep) {
        items.current = data.items.np;
    
        breadcrumList = {
          image: '',
          link: '/',
          title: title,
          l1: 'होम',
          l2: 'लगानीकर्ता सम्बन्ध',
          l3: ''
        }
      } else {
        items.current = data.items.en;
    
        breadcrumList = {
          image: '',
          link: '/',
          title: title,
          l1: 'Home',
          l2: 'Investor Relation',
          l3: ''
        }
      }

  return (
     <Bread data={breadcrumList} >  
        <div className="InterestPageTableContainer">
            <TableContainer sx={{border: '1px solid #c2c2c2'}} component={Paper}>
            <Table sx={{ minWidth: 600 }} aria-label="customized table">

                <TableBody>
                {items.current.map((item, index) => (
                    <StyledTableRow key={index} className='InterestPageTableRow'>
                    <StyledTableCell sx={{ width: '25%' }} className="InterestPageTableCell InterestPageTableDateText" component="th" scope="row">
                        {item.created}
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '50%' }}  className="InterestPageTableCell InterestPageTableTitleText" align="left">{item.title}</StyledTableCell>

                    <StyledTableCell sx={{ width: '25%' }} align="left">
                      <a href={item.DocPath} target="_blank" className='InterestPageTableCell InterestPageTableActionsButton'>
                      <AiOutlineCloudDownload size="22px" />
                        Download
                      </a>
                    </StyledTableCell>
                    </StyledTableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>

            {data?.total >= data?.per_page && (<Pagination style={{ marginTop: '24px' }} className='paginationContainer' count={Math.ceil(data?.total / data?.per_page)} color="primary" size="large" page={isPage} onChange={(e, value) => {setIsPage(value); window.scrollTo(0, 0);}} />)}
          </div>
     </Bread>
  )
}

export default InvestorRelation