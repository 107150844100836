import React, { useRef } from "react";
import Bread from "../../common/Bread";
import TimeAgo from "../../common/TimeAgo";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { LangContext } from "../../context/LangContext";
import { useQuery } from "@tanstack/react-query";
import AxiosClass from "../../common/AxiosClass";
import Loader from "../../common/Loader";

const Detail = () => {
  const item = useRef({});

  const lang = React.useContext(LangContext);
  const nep = lang.state.np;

  const { slug } = useParams();

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["csrDataSingle"],
    queryFn: () =>
      AxiosClass.getApi(`/csr/detail/${slug}`).then((res) => res.data),
  });

  if (isFetching) return <Loader />;

  if (isLoading) return <Loader />;

  if (error) return "An error has occurred: " + error.message;

  console.log(data);

  let breadcrumList;

  if (nep) {
    item.current = data.detail.np;

    breadcrumList = {
      image: "",
      link: "/csr",
      title: `${item.current.Title}`,
      l1: "होम",
      l2: "कर्पोरेट सामाजिक उत्तरदायित्व",
      l3: `${item.current.Title}`,
    };
  } else {
    item.current = data.detail.en;

    breadcrumList = {
      image: "",
      link: "/csr",
      title: `${item.current.Title}`,
      l1: "Home",
      l2: "CSR",
      l3: `${item.current.Title}`,
    };
  }

  return (
    <Bread data={breadcrumList}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={12} sm={12} md={12} key={1}>
          <div className="detail_content">
            <div className="image_detail">
              {item.current.DocPath && (
                <img src={item.current.DocPath} alt="gg" />
              )}
            </div>
            <div className="detail_content-child">
              {/* <h1>Title Goes Here</h1> */}
              <TimeAgo timestamp={item.current.created_on} />
              <div
                dangerouslySetInnerHTML={{ __html: item.current.Description }}
                className="services-ck-content marginTop"
              ></div>

            </div>
          </div>
        </Grid>
      </Grid>
    </Bread>
  );
};

export default Detail;
