import React from "react";
import { LangContext } from "../../context/LangContext";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import branch from "../../assets/information/branch.png";
import contact from "../../assets/information/contact.png";
import faq from "../../assets/information/faq.png";
import info from "../../assets/information/infoOfficerC.png";
import griev from "../../assets/information/grievOfficerC.png";
import facebook from "../../assets/information/social/facebook.png";
import instagram from "../../assets/information/social/insta01.png";
import linkedin from "../../assets/information/social/linkedin.png";
import tiktok from "../../assets/information/social/tiktok01.png";
import youtube from "../../assets/information/social/youtube.png";
import "./information.scss";

import { BsTelephoneForwardFill, BsFillPhoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

import { Link } from "react-router-dom";

const Information = ({ digital, compliance, grievance, information }) => {
  const lang = React.useContext(LangContext);
  const np = lang.state.np;

  const navigate = useNavigate();

  const [expanded, setExpanded] = React.useState("panel3");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="information-section-container">
      <h2 className="section-heading">
        {np ? "हामी मद्दत गर्न यहाँ छौं" : "We're here  to help"}
      </h2>

      <div className="primaryContainer">
        <div className="left-container">
          {/* one */}
          <div className="first-flex-container">
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.8 }}
              className="flex-item"
              onClick={() => navigate("/contact-us")}
            >
              <img src={contact} alt="contact-us" />
              <div className="sub-flex">
                <h3>{np ? "सम्पर्क गर्नुहोस" : "Contact us"}</h3>
                <p>
                  {np
                    ? "हामीलाई यहाँबाट सन्देश पठाउनुहोस् वा हेल्पलाइनमा कल गर्नुहोस्।"
                    : "Message us from here or call to connect to the helpline."}
                </p>
              </div>
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.8 }}
              className="flex-item"
              onClick={() => {
                navigate("/faqs");
              }}
            >
              <img src={faq} alt="faq" />
              <div className="sub-flex">
                <h3>{np ? "प्रश्नहरू" : "FAQs"}</h3>
                <p>
                  {np
                    ? "प्रायः सोधिने प्रश्नहरू खोज्नुहोस्।"
                    : "Search support and most frequently asked questions."}
                </p>
              </div>
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.8 }}
              className="flex-item"
              onClick={() => {
                navigate("/branch-location");
              }}
            >
              <img src={branch} alt="branch" />
              <div className="sub-flex">
                <h3>{np ? "शाखाहरू खोज्नुहोस्" : "Find a branch"}</h3>
                <p>
                  {np
                    ? "आफ्नो वरपर शाखा वा एटीएम खोज्नुहोस्।"
                    : "Find a branch, ATM or specialist around you."}
                </p>
              </div>
            </motion.div>
          </div>

          {/* two */}
          <div className="second-flex-container">
            <div className="oneFlexContainer">
              <div className="aboutContainer">
                <h4>{np ? "हाम्रो बारे" : "ABOUT US"}</h4>
                <div className="flex-container">
                  <Link to={"about-us/company-profile"}>
                    {np ? "कम्पनी प्रोफइल" : "Company Profile"}
                  </Link>
                  <Link to={"/csr"}>{np ? "सामाजिक उत्तरदायित्व" : "CSR"}</Link>
                  <Link to={"about-us/ceo-message"}>
                    {np
                      ? "प्रमुख कार्यकारी अधिकृतको सन्देश"
                      : "Message from CEO"}
                  </Link>
                  <Link to={"products/icfc-bumper-saving-account"}>
                    {np ? "हाम्रा उत्पादनहरु" : "Our Products"}
                  </Link>
                  <Link to={"/career"}>{np ? "क्यारियर" : "Careers"}</Link>
                  {/* <Link to={}>Regulatory Disclosures</Link> */}
                  <Link to={"/news"}>
                    {np ? "सार्वजनिक जानकारी" : "Public Information"}
                  </Link>
                </div>
              </div>
              <div className="followContainer">
                <h4>{np ? "सामाजिक संजाल" : "FOLLOW US"}</h4>
                <div className="flex-container">
                  <a
                    href="https://www.facebook.com/icfcbank/"
                    target="_blank"
                    className="btn-container"
                  >
                    <img src={facebook} alt="facebook-logo" />
                    <p>Facebook</p>
                  </a>

                  <a
                    href="https://www.instagram.com/icfc_finance/"
                    target="_blank"
                    className="btn-container"
                  >
                    <img className="tik" src={instagram} alt="instagram-logo" />
                    <p>Instagram</p>
                  </a>

                  <a
                    href="https://www.instagram.com/icfc_finance/"
                    target="_blank"
                    className="btn-container"
                  >
                    <img src={youtube} alt="youtube-logo" />
                    <p>Youtube</p>
                  </a>

                  <a
                    href="https://www.linkedin.com/company/icfc-finance-limited/"
                    target="_blank"
                    className="btn-container"
                  >
                    <img src={linkedin} alt="linkedin-logo" />
                    <p>Linkedin</p>
                  </a>

                  <a
                    href="https://www.tiktok.com/@icfc_headoffice"
                    target="_blank"
                    className="btn-container"
                  >
                    <img className="tik" src={tiktok} alt="tiktok-logo" />
                    <p>TikTok</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="officers-container">
          <div className="digital-inqueryss">
            <h2>Digital Banking Inquiries</h2>
            <p className="digital-name">(ATM , QR, Mobile Banking)</p>
            <div>
              <MdEmail />
              <p>{digital.email}</p>
            </div>
            <div>
              <BsTelephoneForwardFill />
              <p>{digital.phone} </p>
            </div>
          </div>
          <div className="informationsssss">
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              sx={{
                "& .MuiPaper-root-MuiAccordion-root": {
                  border: "2px solid red",
                  borderRadius: "10px",
                },
                "& .MuiAccordionSummary-root": {
                  height: "20px",
                  backgroundColor: "#003399",
                  color: "#fff",
                  borderRadius: "10px",
                },
                "& .MuiAccordionSummary-root.Mui-expanded": {
                  minHeight: "50px",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: "#fff",
                  },
                }}
              >
                <Typography>Grievance Officer</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="information-accordion-details-container">
                  <img src={grievance.featured_image} alt={grievance.slug} />
                  <p className="officer-name">{grievance.name}</p>
                  <p className="dessss">Deputy Chief Executive Officer</p>
                  <div>
                    <MdEmail />
                    <p>{grievance.email}</p>
                  </div>

                  <div>
                    <BsTelephoneForwardFill />
                    <p>{grievance.contact}</p>
                  </div>
                  <div>
                    <BsFillPhoneFill />
                    <p>{grievance.mobile}</p>
                  </div>
                  <div>
                    <p>
                      <a href="https://gunaso.nrb.org.np" target="_blank">
                        https://gunaso.nrb.org.np
                      </a>
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              sx={{
                "& .MuiAccordionSummary-root": {
                  height: "20px",
                  backgroundColor: "#003399",
                  color: "#fff",
                  borderRadius: "10px",
                },
                "& .MuiAccordionSummary-root.Mui-expanded": {
                  minHeight: "50px",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: "#fff",
                  },
                }}
              >
                <Typography>Information Officer</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="information-accordion-details-container">
                  <img
                    src={information.featured_image}
                    alt={information.slug}
                  />
                  <p className="officer-name">{information.name}</p>
                  <p className="dessss">Senior Manager</p>
                  <div>
                    <MdEmail />
                    <p>{information.email}</p>
                  </div>

                  <div>
                    <BsTelephoneForwardFill />
                    <p>{information.contact}</p>
                  </div>

                  <div>
                    <BsFillPhoneFill />
                    <p>{information.mobile}</p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              sx={{
                "& .MuiAccordionSummary-root": {
                  height: "20px",
                  backgroundColor: "#003399",
                  color: "#fff",
                  borderRadius: "10px",
                },
                "& .MuiAccordionSummary-root.Mui-expanded": {
                  minHeight: "50px",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: "#fff",
                  },
                }}
              >
                <Typography>Compliance Officer</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="information-accordion-details-container">
                  <img src={compliance.featured_image} alt={compliance.slug} />
                  <p className="officer-name">{compliance.name}</p>
                  <p className="dessss">Assistant Manager</p>
                  <div>
                    <MdEmail />
                    <p>{compliance.email}</p>
                  </div>

                  <div>
                    <BsTelephoneForwardFill />
                    <p>{compliance.contact}</p>
                  </div>

                  <div>
                    <BsFillPhoneFill />
                    <p>{compliance.mobile}</p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Information;
