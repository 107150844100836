import React from "react";
import { LangContext } from "../../context/LangContext";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
// import {FromBottom} from '../../utils/Animation';
import cusCare from "../../assets/service/customer-care.png";
// import goldLoan from '../../assets/service/gold-loan.png'
import homeLoan from "../../assets/service/home-loan.png";
import intRate from "../../assets/service/int-rate.png";
import savAc from "../../assets/service/saving-account.png";
// import seniorCiti from '../../assets/service/senior-citi.png'
import side from "../../assets/service/side.png";
import calculator from "../../assets/service/calculator-icon.png";
import safe from "../../assets/service/safe-deposit-icon.png";
// import listTriangle from '../../assets/extras/listTraingle.svg'
import listTriangle from "../../assets/extras/listTraingle2.svg";
// import {BsTriangleFill} from 'react-icons/bs'
import { MdOutlineArrowForwardIos } from "react-icons/md";
// import { Link } from "react-router-dom";

import { CarouselProvider, Slide, Slider } from "pure-react-carousel";

import "./service.scss";

const Service = () => {
  const navigate = useNavigate();

  const controls = useAnimation();
  const [ref, inView] = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const lang = React.useContext(LangContext);
  const np = lang.state.np;

  return (
    <div className="serviceParent">
      <div className="serviceWrapper">
        {window.innerWidth > 480 ? (
          <div className="serviceMainContainer">
            <div className="serviceLeftContainer">
              <div className="serviceItemContainer">
                <img src={savAc} alt="saving-account" />
                <h2>{np ? "बचत खाताहरू" : "Savings Account"}</h2>

                <ul
                  className="serviceItemList"
                  style={{ listStyleImage: `url(${listTriangle})` }}
                >
                  <li className="serviceItemListItem">
                    {/* <BsTriangleFill 
                    className='serviceListIcon' 
                 />       */}
                    {/* <img className='serviceListIconImg' src={listTriangle} alt="listItem" />           */}
                    {np ? "सुविधाहरू र लाभहरू" : "Features & Benefits"}
                  </li>

                  <li className="serviceItemListItem">
                    {/* <BsTriangleFill className='serviceListIcon' />                 */}
                    {np ? "कसरी" : "Frequently Asked Questions"}
                  </li>

                  <li className="serviceItemListItem">
                    {np ? "योग्यता र कागजात" : "Eligibility & Documentation"}
                  </li>
                </ul>

                {/* add double classname for two buttons */}
                <div className="ButtonContainer">
                  {/* <div 
                    whileHover={{
                        scale: 1.05
                    }}
                    whileTap={{ scale: 0.8 }}
                    className='filled-btn'>
                        {np?'आवेदन दिनुहोस्':'Apply Now'}
                    </div> */}

                  <div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    className="know-more-button"
                    onClick={() =>
                      navigate(
                        "product/saving-account/icfc-bumper-saving-account"
                      )
                    }
                  >
                    {np ? "थप जान्नुहोस्" : "Know More"}
                  </div>
                </div>
              </div>

              {/* two */}
              <div className="serviceItemContainer">
                <img src={homeLoan} alt="home-loan" />
                <h2>{np ? "कर्जा" : "Loan Products"}</h2>

                <ul
                  className="serviceItemList"
                  style={{ listStyleImage: `url(${listTriangle})` }}
                >
                  <li className="serviceItemListItem">
                    {/* <BsTriangleFill 
                    className='serviceListIcon' 
                 />       */}
                    {/* <img className='serviceListIconImg' src={listTriangle} alt="listItem" />           */}
                    {np ? "सुविधाहरू र लाभहरू" : "Features & Benefits"}
                  </li>

                  <li className="serviceItemListItem">
                    {/* <BsTriangleFill className='serviceListIcon' />                 */}
                    {np ? "कसरी" : "Frequently Asked Questions"}
                  </li>

                  <li className="serviceItemListItem">
                    {np ? "योग्यता र कागजात" : "Eligibility & Documentation"}
                  </li>
                </ul>

                <div className="ButtonContainer">
                  {/* <div 
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    className='filled-btn'>
                        {np?'आवेदन दिनुहोस्':'Apply Now'}
                    </div> */}

                  <div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    className="know-more-button"
                    onClick={() =>
                      navigate("product/professional-loan/sme-loan")
                    }
                  >
                    {np ? "थप जान्नुहोस्" : "Know More"}
                  </div>
                </div>
              </div>
              {/* end two */}

              {/* three */}
              <div className="serviceItemContainer">
                <img src={cusCare} alt="cusCare" />
                <h2>{np ? "ग्राहक सेवा" : "Customer Care"}</h2>

                <ul
                  className="serviceItemList"
                  style={{ listStyleImage: `url(${listTriangle})` }}
                >
                  <li className="serviceItemListItem">
                    {/* <BsTriangleFill 
                    className='serviceListIcon' 
                 />       */}
                    {/* <img className='serviceListIconImg' src={listTriangle} alt="listItem" />           */}
                    {np ? "सुविधाहरू र लाभहरू" : "Features & Benefits"}
                  </li>

                  <li className="serviceItemListItem">
                    {/* <BsTriangleFill className='serviceListIcon' />                 */}
                    {np ? "कसरी" : "Frequently Asked Questions"}
                  </li>

                  <li className="serviceItemListItem">
                    {np ? "योग्यता र कागजात" : "Eligibility & Documentation"}
                  </li>
                </ul>

                <div className="ButtonContainer">
                  {/* <div 
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    className='filled-btn'>
                        Apply Now
                    </div> */}

                  <div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    className="know-more-button"
                    onClick={() => navigate("/contact-us")}
                  >
                    {np ? "थप जान्नुहोस्" : "Know More"}
                  </div>
                </div>
              </div>
              {/* end three */}

              {/* four */}
              <div className="serviceItemContainer">
                <img src={intRate} alt="intRate" />
                <h2>{np ? "ब्याज दरहरू" : "Interest Rates"}</h2>

                <ul
                  className="serviceItemList"
                  style={{ listStyleImage: `url(${listTriangle})` }}
                >
                  <li className="serviceItemListItem">
                    {/* <BsTriangleFill 
                    className='serviceListIcon' 
                 />       */}
                    {/* <img className='serviceListIconImg' src={listTriangle} alt="listItem" />           */}
                    {np ? "सुविधाहरू र लाभहरू" : "Features & Benefits"}
                  </li>

                  <li className="serviceItemListItem">
                    {/* <BsTriangleFill className='serviceListIcon' />                 */}
                    {np ? "कसरी" : "Frequently Asked Questions"}
                  </li>

                  <li className="serviceItemListItem">
                    {np ? "योग्यता र कागजात" : "Eligibility & Documentation"}
                  </li>
                </ul>

                <div className="ButtonContainer">
                  {/* <div 
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    className='filled-btn'>
                        Apply Now
                    </div> */}

                  <div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    className="know-more-button"
                    onClick={() => navigate("/interest-rates")}
                  >
                    {np ? "थप जान्नुहोस्" : "Know More"}
                  </div>
                </div>
              </div>
              {/* end four */}

              {/* five */}
              <div className="serviceItemContainer">
                <img src={safe} alt="safe" />
                <h2>{np ? "सुरक्षित लकर" : "Safe Deposit Locker"}</h2>

                <ul
                  className="serviceItemList"
                  style={{ listStyleImage: `url(${listTriangle})` }}
                >
                  <li className="serviceItemListItem">
                    {/* <BsTriangleFill 
                    className='serviceListIcon' 
                 />       */}
                    {/* <img className='serviceListIconImg' src={listTriangle} alt="listItem" />           */}
                    {np ? "सुविधाहरू र लाभहरू" : "Features & Benefits"}
                  </li>

                  <li className="serviceItemListItem">
                    Frequently Asked Questions
                  </li>

                  <li className="serviceItemListItem">
                    {np ? "योग्यता र कागजात" : "Eligibility & Documentation"}
                  </li>
                </ul>

                <div className="ButtonContainer">
                  {/* <div 
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    className='filled-btn'>
                        {np?'आवेदन दिनुहोस्':'Apply Now'}
                    </div> */}

                  <div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    className="know-more-button"
                    onClick={() => navigate("services/locker-facility")}
                  >
                    {np ? "थप जान्नुहोस्" : "Know More"}
                  </div>
                </div>
              </div>
              {/* end five */}

              {/* six */}
              <div className="serviceItemContainer">
                <img src={calculator} alt="calculator" />
                <h2>{np ? "क्याल्कुलेटरहरू" : "Calculators"}</h2>

                <ul
                  className="serviceItemList"
                  style={{ listStyleImage: `url(${listTriangle})` }}
                >
                  <li className="serviceItemListItem">
                    {/* <BsTriangleFill 
                    className='serviceListIcon' 
                 />       */}
                    {/* <img className='serviceListIconImg' src={listTriangle} alt="listItem" />           */}
                    {np ? "सुविधाहरू र लाभहरू" : "Features & Benefits"}
                  </li>

                  <li className="serviceItemListItem">
                    {/* <BsTriangleFill className='serviceListIcon' />                 */}
                    {np ? "कसरी" : "Frequently Asked Questions"}
                  </li>

                  <li className="serviceItemListItem">
                    {np ? "योग्यता र कागजात" : "Eligibility & Documentation"}
                  </li>
                </ul>

                <div className="ButtonContainer">
                  {/* <div 
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    className='filled-btn'>
                        Apply Now
                    </div> */}

                  <div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.8 }}
                    className="know-more-button"
                    onClick={() => navigate("/calculators")}
                  >
                    {np ? "थप जान्नुहोस्" : "Know More"}
                  </div>
                </div>
              </div>
              {/* end six */}
            </div>

            <div className="serviceRightContainer">
              <h3>{np ? "द्रुत लिङ्कहरू" : "Quick Links"}</h3>
              <div className="btn-container">
                <a
                  className="btn"
                  href="https://demo.nyatapol.com/interest-rates"
                  target="_blank"
                >
                  <p>{np ? "ब्याज दरहरू" : "Interest Rates"}</p>
                  <MdOutlineArrowForwardIos className="icon" />
                </a>

                <a
                  className="btn"
                  href="https://demo.nyatapol.com/calculators"
                  target="_blank"
                >
                  <p>{np ? "क्याल्कुलेटरहरू" : "Calculators"}</p>
                  <MdOutlineArrowForwardIos className="icon" />
                </a>

                <a
                  href="https://demo.nyatapol.com/product"
                  target="_blank"
                  className="btn"
                >
                  <p>{np ? "उत्पादनहरू" : "Products"}</p>
                  <MdOutlineArrowForwardIos className="icon" />
                </a>

                <a
                  href="https://demo.nyatapol.com/services/icfc-ebanking"
                  target="_blank"
                  className="btn"
                >
                  <p>{np ? "सेवाहरू" : "Services"}</p>
                  <MdOutlineArrowForwardIos className="icon" />
                </a>

                <a
                  href="https://demo.nyatapol.com/branch-location"
                  target="_blank"
                  className="btn"
                >
                  <p>{np ? "शाखा स्थानहरू" : "Branch Locations"}</p>
                  <MdOutlineArrowForwardIos className="icon" />
                </a>

                <a
                  href="https://demo.nyatapol.com/atm-location"
                  target="_blank"
                  className="btn"
                >
                  <p>{np ? "एटीएम स्थानहरू" : "ATM Locations"}</p>
                  <MdOutlineArrowForwardIos className="icon" />
                </a>

                <a
                  href="https://demo.nyatapol.com/forex"
                  target="_blank"
                  className="btn"
                >
                  <p>{np ? "विदेशी मुद्रा दर" : "Forex Rates"}</p>
                  <MdOutlineArrowForwardIos className="icon" />
                </a>

                <a
                  href="https://demo.nyatapol.com/career"
                  target="_blank"
                  className="btn"
                >
                  <p>{np ? "रिक्तता सूचना" : "Vacancy Notice"}</p>
                  <MdOutlineArrowForwardIos className="icon" />
                </a>
              </div>
              <img src={side} alt="side-img" />
            </div>
          </div>
        ) : (
          <div className="mobileServiceContainer">
            <CarouselProvider
              className="mobileService"
              visibleSlides={1}
              totalSlides={6}
              step={1}
              naturalSlideWidth={1}
              naturalSlideHeight={1.2}
              hasMasterSpinner={false}
              isPlaying={true}
              interval={10000}
              infinite={true}
            >
              <Slider>
                <Slide>
                  <div className="serviceItemContainer">
                    <img src={savAc} alt="saving-account" />
                    <h2>{np ? "बचत खाताहरू" : "Savings Account"}</h2>

                    <ul
                      className="serviceItemList"
                      style={{ listStyleImage: `url(${listTriangle})` }}
                    >
                      <li className="serviceItemListItem">
                        {/* <BsTriangleFill 
                    className='serviceListIcon' 
                 />       */}
                        {/* <img className='serviceListIconImg' src={listTriangle} alt="listItem" />           */}
                        {np ? "सुविधाहरू र लाभहरू" : "Features & Benefits"}
                      </li>

                      <li className="serviceItemListItem">
                        {/* <BsTriangleFill className='serviceListIcon' />                 */}
                        {np ? "कसरी" : "Frequently Asked Questions"}
                      </li>

                      <li className="serviceItemListItem">
                        {np
                          ? "योग्यता र कागजात"
                          : "Eligibility & Documentation"}
                      </li>
                    </ul>

                    <div className="ButtonContainer double">
                      <div
                        whileHover={{
                          scale: 1.05,
                        }}
                        whileTap={{ scale: 0.8 }}
                        className="filled-btn"
                      >
                        {np ? "आवेदन दिनुहोस्" : "Apply Now"}
                      </div>

                      <div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.8 }}
                        className="know-more-button"
                        onClick={() =>
                          navigate(
                            "product/saving-account/icfc-bumper-saving-account"
                          )
                        }
                      >
                        {np ? "थप जान्नुहोस्" : "Know More"}
                      </div>
                    </div>
                  </div>
                </Slide>

                <Slide>
                  {/* two */}
                  <div className="serviceItemContainer">
                    <img src={homeLoan} alt="home-loan" />
                    <h2>{np ? "कर्जा" : "Loans"}</h2>

                    <ul
                      className="serviceItemList"
                      style={{ listStyleImage: `url(${listTriangle})` }}
                    >
                      <li className="serviceItemListItem">
                        {/* <BsTriangleFill 
                    className='serviceListIcon' 
                 />       */}
                        {/* <img className='serviceListIconImg' src={listTriangle} alt="listItem" />           */}
                        {np ? "सुविधाहरू र लाभहरू" : "Features & Benefits"}
                      </li>

                      <li className="serviceItemListItem">
                        {/* <BsTriangleFill className='serviceListIcon' />                 */}
                        {np ? "कसरी" : "Frequently Asked Questions"}
                      </li>

                      <li className="serviceItemListItem">
                        {np
                          ? "योग्यता र कागजात"
                          : "Eligibility & Documentation"}
                      </li>
                    </ul>

                    <div className="ButtonContainer double">
                      <div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.8 }}
                        className="filled-btn"
                      >
                        {np ? "आवेदन दिनुहोस्" : "Apply Now"}
                      </div>

                      <div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.8 }}
                        className="know-more-button"
                        onClick={() =>
                          navigate("product/professional-loan/sme-loan")
                        }
                      >
                        {np ? "थप जान्नुहोस्" : "Know More"}
                      </div>
                    </div>
                  </div>
                  {/* end two */}
                </Slide>

                <Slide>
                  {/* three */}
                  <div className="serviceItemContainer">
                    <img src={cusCare} alt="cusCare" />
                    <h2>{np ? "ग्राहक सेवा" : "Customer Care"}</h2>

                    <ul
                      className="serviceItemList"
                      style={{ listStyleImage: `url(${listTriangle})` }}
                    >
                      <li className="serviceItemListItem">
                        {/* <BsTriangleFill 
                    className='serviceListIcon' 
                 />       */}
                        {/* <img className='serviceListIconImg' src={listTriangle} alt="listItem" />           */}
                        {np ? "सुविधाहरू र लाभहरू" : "Features & Benefits"}
                      </li>

                      <li className="serviceItemListItem">
                        {/* <BsTriangleFill className='serviceListIcon' />                 */}
                        {np ? "कसरी" : "Frequently Asked Questions"}
                      </li>

                      <li className="serviceItemListItem">
                        {np
                          ? "योग्यता र कागजात"
                          : "Eligibility & Documentation"}
                      </li>
                    </ul>

                    <div className="ButtonContainer">
                      {/* <div 
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    className='filled-btn'>
                        Apply Now
                    </div> */}

                      <div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.8 }}
                        className="know-more-button"
                        onClick={() => navigate("/contact-us")}
                      >
                        {np ? "थप जान्नुहोस्" : "Know More"}
                      </div>
                    </div>
                  </div>
                  {/* end three */}
                </Slide>

                <Slide>
                  {/* four */}
                  <div className="serviceItemContainer">
                    <img src={intRate} alt="intRate" />
                    <h2>{np ? "ब्याज दरहरू" : "Interest Rates"}</h2>

                    <ul
                      className="serviceItemList"
                      style={{ listStyleImage: `url(${listTriangle})` }}
                    >
                      <li className="serviceItemListItem">
                        {/* <BsTriangleFill 
                    className='serviceListIcon' 
                 />       */}
                        {/* <img className='serviceListIconImg' src={listTriangle} alt="listItem" />           */}
                        {np ? "सुविधाहरू र लाभहरू" : "Features & Benefits"}
                      </li>

                      <li className="serviceItemListItem">
                        {/* <BsTriangleFill className='serviceListIcon' />                 */}
                        {np ? "कसरी" : "Frequently Asked Questions"}
                      </li>

                      <li className="serviceItemListItem">
                        {np
                          ? "योग्यता र कागजात"
                          : "Eligibility & Documentation"}
                      </li>
                    </ul>

                    <div className="ButtonContainer">
                      {/* <div 
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    className='filled-btn'>
                        Apply Now
                    </div> */}

                      <div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.8 }}
                        className="know-more-button"
                        onClick={() => navigate("/interest-rates")}
                      >
                        {np ? "थप जान्नुहोस्" : "Know More"}
                      </div>
                    </div>
                  </div>
                  {/* end four */}
                </Slide>

                <Slide>
                  {/* five */}
                  <div className="serviceItemContainer">
                    <img src={safe} alt="safe" />
                    <h2>{np ? "सुरक्षित लकर" : "Safe Deposit Locker"}</h2>

                    <ul
                      className="serviceItemList"
                      style={{ listStyleImage: `url(${listTriangle})` }}
                    >
                      <li className="serviceItemListItem">
                        {/* <BsTriangleFill 
                    className='serviceListIcon' 
                 />       */}
                        {/* <img className='serviceListIconImg' src={listTriangle} alt="listItem" />           */}
                        {np ? "सुविधाहरू र लाभहरू" : "Features & Benefits"}
                      </li>

                      {/* <li className='serviceItemListItem'>
                         
                    Frequently Asked Questions
                </li> */}

                      <li className="serviceItemListItem">
                        {np
                          ? "योग्यता र कागजात"
                          : "Eligibility & Documentation"}
                      </li>
                    </ul>

                    <div className="ButtonContainer double">
                      <div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.8 }}
                        className="filled-btn"
                      >
                        {np ? "आवेदन दिनुहोस्" : "Apply Now"}
                      </div>

                      <div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.8 }}
                        className="know-more-button"
                        onClick={() => navigate("services/locker-facility")}
                      >
                        {np ? "थप जान्नुहोस्" : "Know More"}
                      </div>
                    </div>
                  </div>
                  {/* end five */}
                </Slide>

                <Slide>
                  {/* six */}
                  <div className="serviceItemContainer">
                    <img src={calculator} alt="calculator" />
                    <h2>{np ? "क्याल्कुलेटरहरू" : "Calculators"}</h2>

                    <ul
                      className="serviceItemList"
                      style={{ listStyleImage: `url(${listTriangle})` }}
                    >
                      <li className="serviceItemListItem">
                        {/* <BsTriangleFill 
                    className='serviceListIcon' 
                 />       */}
                        {/* <img className='serviceListIconImg' src={listTriangle} alt="listItem" />           */}
                        {np ? "सुविधाहरू र लाभहरू" : "Features & Benefits"}
                      </li>

                      <li className="serviceItemListItem">
                        {/* <BsTriangleFill className='serviceListIcon' />                 */}
                        {np ? "कसरी" : "Frequently Asked Questions"}
                      </li>

                      <li className="serviceItemListItem">
                        {np
                          ? "योग्यता र कागजात"
                          : "Eligibility & Documentation"}
                      </li>
                    </ul>

                    <div className="ButtonContainer">
                      {/* <div 
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    className='filled-btn'>
                        Apply Now
                    </div> */}

                      <div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.8 }}
                        className="know-more-button"
                        onClick={() => navigate("/calculators")}
                      >
                        {np ? "थप जान्नुहोस्" : "Know More"}
                      </div>
                    </div>
                  </div>
                  {/* end six */}
                </Slide>
              </Slider>
            </CarouselProvider>
          </div>
        )}
      </div>
    </div>
  );
};

export default Service;
