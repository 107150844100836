import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
// import main from '../../assets/news/main.png';
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import TimeAgo from "../../common/TimeAgo";
import striptags from "striptags";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
  Image,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { LangContext } from "../../context/LangContext";
import "./news.scss";

const News = ({ dataNews, dataSlider }) => {
  const itemNews = useRef({});
  const itemSlider = useRef({});

  const lang = React.useContext(LangContext);
  const np = lang.state.np;
  const navigate = useNavigate();
  const controls = useAnimation();
  const [ref, inView] = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  // console.log("dataNews ->", dataNews)
  function capFirstLetterInSentence(sentence) {
    let LWords = sentence.toLowerCase();
    let words = LWords.split(" ").map((word) => {
      return word[0]?.toUpperCase() + word.slice(1);
      // str?.toUpperCase() || ''
    });
    return words.join(" ");
  }

  if (np) {
    itemNews.current = dataNews.np;
    itemSlider.current = dataSlider.np;
  } else {
    itemNews.current = dataNews.en;
    itemSlider.current = dataSlider.en;
  }

  return (
    <div className="newsSectionMainContainer">
      {/* <CarouselProvider
        className='news-carousel-provider'
        visibleSlides={1}
        totalSlides={itemSlider?.current?.length}
        step={1}
        naturalSlideWidth={3}
        naturalSlideHeight={4}
        hasMasterSpinner
        isPlaying={true}
        interval={10000}
        infinite={true}
      >
        <Slider className='sliderContainer'>
          {itemSlider?.current?.map((i, index) =>
            <Slide key={index} className='singleSlide' index={index}>
              <Image className='image' src={i.file} alt={index} />
              <div className='image-overlay'>
                <div className='text'>
                
                {np?(
                        <p dangerouslySetInnerHTML={{ __html: striptags(`${i?.DescriptionNepali?.substring(0, 150)}...`) }}></p>
                ):(
                        <p dangerouslySetInnerHTML={{ __html: striptags(`${i?.Description?.substring(0, 150)}...`) }}></p>
                )}
                
                  <div 
                  onClick={() => {navigate(`/news/${i.slug}`);}}
                  className='read-btn-container'>
                    <div
                      className='read-btn'>
                      {np?'थप पढ्नुहोस्':'Read More'}
                    </div>
                  </div>

                </div>
              </div>
            </Slide>
          )}
        </Slider>

        <div className='next-back-btn-container'>
          <ButtonBack className='cus-back'>
            <BsChevronLeft
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.8 }}
              size='40px'
              color="white"
            />
          </ButtonBack>
          <ButtonNext className='cus-next'>
            <BsChevronRight
              size='40px'
              color="white"
            />
          </ButtonNext>
        </div>

      </CarouselProvider> */}

      <div className="facebook-page-plugin-container">
        <iframe
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ficfcbank%2F&tabs=timeline&width=312&height=524&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
          width="312"
          height="524"
          style={{ border: "none", overflow: "hidden", borderRadius: "8px" }}
          scrolling="no"
          frameborder="0"
          allowfullscreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        />
      </div>

      <div className="contentMainContainer">
        <h2>{np ? "पछिल्लो वित्तीय सूचनाहरू" : "Latest Financial News"}</h2>

        <div className="newsGrid">
          {itemNews?.current?.map((i, index) => (
            <div
              key={index}
              className="newsItem"
              onClick={() => {
                navigate(`/news/${i.slug}`);
              }}
            >
              <img src={i.file} alt="img01" />
              <div className="titleTextContainer">
                <h3>{capFirstLetterInSentence(i.Title)}</h3>

                <TimeAgo timestamp={i.created_on} />
              </div>
            </div>
          ))}
        </div>

        <div className="read-all-button" onClick={() => navigate("/news")}>
          Read All News
        </div>
      </div>
    </div>
  );
};

export default News;
