import {PropagateLoader} from 'react-spinners'
import logo from '../assets/navbar/logolg.webp'

const Loader = () => {
  return (
    <div className='customLoaderContainer'>
        {/* <div className='imgBox'> */}
            <img className='logo' src={logo} alt="logo" />    
        {/* </div> */}
        
        <PropagateLoader className='loader' size={16} color='#02033F' loading />
    </div>
  )
}

export default Loader