import React, { useMemo, useState } from "react";
import Bread from "../../common/Bread";
import { BsTelephoneForwardFill, BsFillPhoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
// import griev from "../../assets/information/grievOfficerC.png";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { LangContext } from "../../context/LangContext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import AxiosClass from "../../common/AxiosClass";
import Loader from "../../common/Loader";
import "./branchwiseOfficers.scss";

const BranchwiseOfficers = () => {
  const [title, setTitle] = useState("");

  const { slug } = useParams();

  function titleSwitch() {
    switch (slug) {
      case "grievance":
        {
          setTitle("Grievance Officers");
        }
        break;
      case "information":
        {
          setTitle("Information Officers");
        }
        break;
      default:
        {
          setTitle("Officers");
        }
        break;
    }
  }

  var breadMemo = useMemo(titleSwitch, [slug]);

  const breadcrumList = {
    image: "",
    title: title,
    l1: "Home",
    l2: "Officers",
    l3: "",
  };

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["branchOfficers", slug],
    queryFn: () =>
      AxiosClass.getApi(`/officers/${slug}`).then((res) => res.data),
  });

  if (isFetching) return <Loader />;

  if (isLoading) return <Loader />;

  if (error) return "An error has occurred: " + error.message;

  return (
    <Bread data={breadcrumList}>
      {title === "Grievance Officers" ? (
        <div className="officers-grid-container">
          {data.items && (
            <>
              {data.items.map((i) => (
                <div key={i.id} className="officer">
                  <div className="branch">
                    <h3>{i.branch}</h3>
                  </div>

                  <div className="paper">
                    <div className="detail-container">
                      <img src={i.featured_image} alt={i.slug} />
                      <p className="officer-name">{i.name}</p>
                      <div>
                        <MdEmail />
                        <p>{i.email}</p>
                      </div>

                      <div>
                        <BsTelephoneForwardFill />
                        <p>{i.contact}</p>
                      </div>

                      <div>
                        <BsFillPhoneFill />
                        <p>{i.mobile}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      ) : (
        <Tabs className="officersPageTabContainer" defaultIndex={0}>
          <TabList className="officersPageTabsButtonContainer">
            <Tab key={0} className="officersPageTabButton">
              Inside kathmandu Valley
            </Tab>

            <Tab key={1} className="officersPageTabButton">
              Outside Valley
            </Tab>
          </TabList>

          <TabPanel key={0}>
            <div className="officers-grid-container">
              {data.items && (
                <>
                  {data.items
                    .filter((obj) => {
                      return obj.valley === "Inside";
                    })
                    .map((i) => (
                      <div key={i.id} className="officer">
                        <div className="branch">
                          <h3>{i.branch}</h3>
                        </div>

                        <div className="paper">
                          <div className="detail-container">
                            <img src={i.featured_image} alt={i.slug} />
                            <p className="officer-name">{i.name}</p>
                            <div>
                              <MdEmail />
                              <p>{i.email}</p>
                            </div>

                            <div>
                              <BsTelephoneForwardFill />
                              <p>{i.contact}</p>
                            </div>

                            <div>
                              <BsFillPhoneFill />
                              <p>{i.mobile}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          </TabPanel>

          <TabPanel key={1}>
            <div className="officers-grid-container">
              {data.items && (
                <>
                  {data.items
                    .filter((obj) => {
                      return obj.valley === "Outside";
                    })
                    .map((i) => (
                      <div key={i.id} className="officer">
                        <div className="branch">
                          <h3>{i.branch}</h3>
                        </div>

                        <div className="paper">
                          <div className="detail-container">
                            <img src={i.featured_image} alt={i.slug} />
                            <p className="officer-name">{i.name}</p>
                            <div>
                              <MdEmail />
                              <p>{i.email}</p>
                            </div>

                            <div>
                              <BsTelephoneForwardFill />
                              <p>{i.contact}</p>
                            </div>

                            <div>
                              <BsFillPhoneFill />
                              <p>{i.mobile}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          </TabPanel>
        </Tabs>
      )}
    </Bread>
  );
};

export default BranchwiseOfficers;
