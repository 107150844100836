import React from 'react'
import Bread from '../../common/Bread'
import axios from 'axios';
import { useQuery } from "@tanstack/react-query";
import {Grid} from '@mui/material';
import TimeAgo from '../../common/TimeAgo';
import {Link} from 'react-router-dom';
import './career.scss'
import Loader from '../../common/Loader';

const Career = () => {

    const breadcrumList = {
        image: '',
        title: 'Career',
        l1: 'Home',
        l2: 'Career',
        l3: ''  
      }

    const { isLoading, error, data, isFetching } = useQuery({
        queryKey: ["careerData"],
        queryFn: () =>
          axios
            .get(`${process.env.REACT_APP_BASE_URL}/career/all`)
            .then((res) => res.data),
      });
  
      if (isFetching) return(
        <Loader />
      );
    
      if (isLoading) return(
        <Loader />
      );
    
      if (error) return "An error has occurred: " + error.message;
  return (
    <Bread data={breadcrumList} >
        <h1 className='careerPageHeader'>Open Positions</h1>
        {data.items.en.length > 0 ? (
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {data.items.en.map((item, index) =>
          <Grid item xs={12} sm={4} md={4} key={index}>
            <div className='box-item-news'>
              <div className='img-news'>
                <img src={item.DocPath} alt={item.Title} />
              </div>
              <div className='content-news'>
                <TimeAgo timestamp={item.created_on} />

                <h1><Link to={`/career/${item.id}`}>{item.Title.substring(0, 30)}</Link></h1>
              </div>
            </div>
          </Grid>
        )}
      </Grid>  
        ) : (
            <h2 style={{color:'#0f2137'}}>No positions available at the moment.</h2>
        )}
  
    </Bread>
  )
}

export default Career