import React,{useEffect, useState, useRef, useCallback} from 'react'
import {
    GoogleMap,
    Marker,
    InfoWindow,
    useLoadScript
} from "@react-google-maps/api";
import './branchMap.scss'

const BranchMap = ({data, selected}) => {
    const [activeMarker, setActiveMarker] = useState(null);
    const [zoom, setZoom] = useState(8);
    const mapRef = useRef();

    const [center, setCenter] = useState({
      lat: 27.7172,
      lng: 85.3240,
    })

    const onLoad = useCallback((map) => (mapRef.current = map), []);

    const containerStyle = {
      height: `${window.innerWidth > 768 ? '70vh' : '50vh' }`
    };

    const handleActiveMarker = ({id, lat, lng}) => {
        if (id === activeMarker) {
          return;
        }
        setCenter({lat: lat, lng: lng });
        setTimeout(() => {
          setActiveMarker(id);
          setZoom(10);
        }, 1000);
    };

    useEffect(() => {
      handleActiveMarker({id: selected.id, lat: selected.lat, lng: selected.lng})
    }, [selected])

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDCi1XRM_e1FjEN7enq4xIuzLVdc8V7Mms"
    });
    
  return isLoaded ? (
    <GoogleMap
    zoom={zoom}
    center={center}
    mapContainerStyle={containerStyle}
    >
      {data?.map((pos) => (
            <Marker
              key={pos.id}
              onLoad={onLoad}
              position={{ lat: Number(pos.latitude), lng: Number(pos.longitude) }}
              onClick={() => handleActiveMarker({id: pos.id, lat: pos.latitude, lng: pos.longitude})}
            >
            {activeMarker === pos.id ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div>{pos.PageTitle}</div>
              </InfoWindow>
            ) : null}
            </Marker>
          ))}
    </GoogleMap>
  ): (
      <p>Loading...</p>
  )
}

export default BranchMap