import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import instance from "../../utils/customInstance";
import { useNavigate } from "react-router-dom";
import Bread from "../../common/Bread";
import contactSVG from "../../assets/contactSVG.svg";
import { ClipLoader } from "react-spinners";
import grievancess from "../../assets/information/grievOfficer.jpg";
import informationss from "../../assets/information/infoOfficer.jpg";
import "./grievanceForm.scss";

const GrievanceForm = () => {
  const breadcrumList = {
    image: "",
    link: "",
    title: "Grievance",
    l1: "Home",
    l2: "Grievance",
    l3: "",
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    formState,
  } = useForm();
  const { isDirty, isSubmitting, isSubmitSuccessful } = formState;
  const [values, setValues] = useState(null);
  const [loading, setLoading] = useState(false);
  const [verificationLoad, setVerificationLoad] = useState(false);
  const [vCode, setVCode] = useState();

  const navigate = useNavigate();
  function checkProgressHandleClick() {
    navigate("/grievance-response");
  }
  function navigateToHome() {
    navigate("/GrievanceResForm");
  }
  const onSubmit = async (values) => {
    setLoading(true);

    try {
      if (verificationLoad === false) {
        const { data } = await instance.post("grievance/otp", values);

        if (data?.error) {
          toast.error(data.error);
          setLoading(false);
        } else {
          toast.success(
            "Please Check Your Email and type your verification code here."
          );
          setVCode(data.otp_code);
          setLoading(false);
        }
      } else {
        if (Number(values.verification) === vCode) {
          const { data } = await instance.post("grievance/form", values);

          if (data?.error) {
            toast.error(data.error);
            setLoading(false);
          } else {
            toast.success("Message Successfully Sent.");
            navigateToHome();
            setLoading(false);
          }
        } else {
          toast.error("The verification code does not match. Please try again");
          setLoading(false);
        }
      }
    } catch (err) {
      toast.error("Failed to send. Please try again.");
      console.log(err);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      setVerificationLoad(true);
    }
  }, [formState]);

  return (
    <Bread data={breadcrumList}>
      <div>
        <div className="grievanceFormMainContainer">
          <img
            className="grievanceFormImage"
            src={contactSVG}
            alt="contact svg"
          />
          <div className="grievancePageFormContainer">
            <h3>
              {verificationLoad
                ? "Please find the code on the email you provided"
                : "How may We help you?"}
            </h3>

            <form onSubmit={handleSubmit(onSubmit)}>
              {!verificationLoad && (
                <>
                  <div className="grievanceFormInputContainer">
                    <label>Full Name</label>
                    <input
                      type="text"
                      placeholder="Full Name"
                      name="name"
                      {...register("name", {
                        required: "Full Name is Required",
                      })}
                    />
                    <span>{errors.name?.message}</span>
                  </div>
                  <div className="grievanceFormInputContainer">
                    <label>Email Address</label>
                    <input
                      type="text"
                      placeholder="Email Address"
                      name="email"
                      {...register("email", {
                        required: "Email is Required",
                        pattern: {
                          value:
                            /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    <span>{errors.email?.message}</span>
                  </div>
                  <div className="grievanceFormInputContainer">
                    <label>Mobile Number</label>
                    <input
                      type="number"
                      placeholder="Mobile Number"
                      name="mobno"
                      {...register("mobno", {
                        required: "Mobile Number is Required",
                        pattern: {
                          value:
                            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                          message: "Invalid Phone or Mobile Number",
                        },
                      })}
                    />
                    <span>{errors.mobno?.message}</span>
                  </div>
                  <div className="grievanceFormInputContainer">
                    <label>Branch</label>
                    <input
                      type="text"
                      placeholder="Enter branch name"
                      name="branch"
                      {...register("branch", {
                        required: "Branch is Required",
                      })}
                    />
                    <span>{errors.branch?.message}</span>
                  </div>
                  <div className="grievanceFormInputContainer">
                    <label>Subject</label>
                    <input
                      type="text"
                      placeholder="Subject"
                      name="subject"
                      {...register("subject", {
                        required: "Subject is Required",
                        pattern: {
                          value: /^.{0,200}$/,
                          message: "Subject must be less than 150 letters",
                        },
                      })}
                    />
                    <span>{errors.subject?.message}</span>
                  </div>

                  <div className="grievanceFormInputContainer">
                    <label>Attachment file</label>
                    <input
                      type="file"
                      placeholder="file"
                      name="file"
                      {...register("attachment", {
                        pattern: {
                          allowFileTypeValidation: true,
                          allowedExtensions: /(\.jpg|\.jpeg|\.png|\.gif)$/i,
                        },
                      })}
                    />
                    <span>{errors.attachment?.message}</span>
                  </div>

                  <div className="grievanceFormInputContainer">
                    <label>Your Message</label>
                    <textarea
                      rows="5"
                      placeholder="Your Message"
                      name="issue"
                      {...register("issue", {
                        required: "Message is Required",
                        pattern: {
                          value: /^.{10,5000}$/,
                          message: "Message must be between 10 to 5000 letters",
                        },
                      })}
                    />
                    <span>{errors.issue?.message}</span>
                  </div>
                </>
              )}

              {verificationLoad && (
                <div className="grievanceFormInputContainer">
                  <label>Email Verification Code</label>
                  <input
                    type="text"
                    placeholder="Verification Code"
                    name="verification"
                    {...register("verification", {
                      required: "Verification Code is Required",
                    })}
                  />
                  <span>{errors.verification?.message}</span>
                </div>
              )}

              <div className="grievanceFormButtonsContainer">
                <button
                  className="grievanceFormResetButton grievanceSubmitButton"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {loading && <ClipLoader size={20} color="#fff"></ClipLoader>}
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="officers-profile">
          <div className="information-profile">
            <div className="i-img">
              <img src={informationss} alt="image" />
            </div>
            <div className="i-details">
              <h2>Information officer</h2>
              <h3>Mr. Sanjaya Thapaliya</h3>
              <p>Senior Manager</p>
              <p>
                <b>Email:</b> sanjaya.thapaliya@icfcbank.com
              </p>
              <p>
                <b>Phone number:</b> 01-4525292 ext 207
              </p>
              <p>
                <b>Mobile number:</b> 9851107577
              </p>
            </div>
          </div>
          <div className="grievance-profile">
            <div className="i-img">
              <img src={grievancess} alt="image" />
            </div>
            <div className="i-details">
              <h2>Grievance officer</h2>
              <h3>Mr. Amit Shrestha</h3>
              <p>Deputy Chief Executive Officer</p>
              <p>
                <b>Email:</b> amit.shrestha@icfcbank.com
              </p>
              <p>
                <b>Phone number:</b> 01-4525292 ext 203
              </p>
              <p>
                <b>Mobile number:</b> 9851053756
              </p>
              <p>
                <a href="https://gunaso.nrb.org.np" target="_blank">
                  https://gunaso.nrb.org.np
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Bread>
  );
};

export default GrievanceForm;
